import React from "react"

import { StaticImage } from "gatsby-plugin-image"
import { navigate } from "gatsby"

import { Cover, Button } from "../components"

const NotFound = () => {
  return (
    <div>
      <Cover>
        <div className="textContainer">
          <div className="content">
            <h1>Page Not Found</h1>
            <p className="font-style-4">
              This page either does not exist or is not available.
            </p>
            <Button
              label="Back to Home"
              onClick={() => {
                navigate("/")
              }}
            />
          </div>
        </div>
        <div className="imageContainer">
          <StaticImage src="../images/about/img-about-cover.png" alt="" />
        </div>
      </Cover>
    </div>
  )
}

export default NotFound
